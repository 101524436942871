<script>
	import * as Sentry from '@sentry/svelte';
	import { page } from '$app/stores';
	import { values } from 'lodash-es';
	import { browser } from '$app/env';
	import analytics from '$utils/google-analytics';

	function replaceRoute(pathname, replacer) {
		return pathname.replace(`/${replacer}`, '');
	}

	$: if ($page.params && $page.url.pathname && browser) {
		const pathnameFormatted = values($page.params).reduce(replaceRoute, $page.url.pathname || '');
		const language = $page.params.lang;

		Sentry.configureScope((scope) => {
			scope.setTransactionName(pathnameFormatted);
		});

		if (import.meta.env.PROD) {
			analytics.page({
				page_path: pathnameFormatted,
				language
			});
		}
	}
</script>

<slot />
